import { useCallback, useState } from 'react'
import { fetchAuthApi } from '@crystal-eyes/utils/apis/authApi'

export type LoginOptions = {
  reactivate?: boolean
  gToken?: string
  twoFactorCode?: string
  redirectUri?: string
}

export type LoginState = {
  success?: boolean
  loading?: boolean
  error?: any
  doLogin: (email: string, password: string, opts?: LoginOptions) => void
}

export enum LoginErrors {
  TooManyAttempts = 'TWO_MANY_LOGIN_ATTEMPTS_ERROR',
  NeedTwoFactor = 'NEED_TWO_FACTOR_AUTH_ERROR',
  Unknown = 'UNKNOWN_AUTH_ERROR',
  Invalid = 'INVALID_AUTH_ERROR',
}

type LoginTokens = {
  token: string
  refresh: string
}

export default function useLogin(
  onLogin: (tokens: LoginTokens) => void,
): LoginState {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any | null>(null)
  const [success, setSuccess] = useState<any | null>(false)

  const loginCallback = useCallback(onLogin, [onLogin])

  const doLogin = useCallback(
    (email: string, password: string, opts: LoginOptions = {}) => {
      setLoading(true)
      setError(null)

      fetchAuthApi('tokens', {
        method: 'POST',
        body: JSON.stringify({ email, password, opts }),
      })
        .then(resp => {
          return resp.json()
        })
        .then(respBody => {
          loginCallback(respBody)
          setLoading(false)
          setSuccess(true)
        })
        .catch(err => {
          setLoading(false)

          if (err.status === 404) {
            setError(LoginErrors.Invalid)
          } else if (err.status === 401) {
            setError(LoginErrors.TooManyAttempts)
          } else if (err.body) {
            if (err.body.error && err.body.error.includes('Two-factor')) {
              setError(LoginErrors.NeedTwoFactor)
            } else {
              setError(LoginErrors.Unknown)
            }
          } else {
            setError(LoginErrors.Unknown)
          }
        })
    },
    [loginCallback],
  )

  return {
    success,
    loading: loading,
    error: error,
    doLogin,
  }
}
